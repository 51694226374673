<template>
  <div class='chartBoard'></div>
</template>
<script>
let elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: 'chartBoard',
  props: {
    option: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return{
      chart: null /* echart */
    }
  },
  methods:{
    updateChartView() { // echart图
      var _this = this;
      _this.chart.setOption(_this.option,true);
      window.addEventListener('resize', ()=> {
        _this.chart.resize();
      })
    },
    /**
     * 当窗口缩放时，echart动态调整自身大小
     */
    handleWindowResize() {
      if (!this.chart) return;
      this.chart.resize();
    }
  },
  mounted(){
    this.chart = this.$echarts.init(this.$el);
    this.$nextTick(_=>{
      this.handleWindowResize()
    })
    //监听元素变化
    let erd = elementResizeDetectorMaker();
    let that = this;
    erd.listenTo(this.$el, function () {
        that.$nextTick(function () {
            //使echarts尺寸重置
            that.handleWindowResize()
        })
    })
  },
  beforeDestory() {

  }

}
</script>

<style scoped>
  .chartBoard {
    width: inherit;
    height: inherit;
  }

</style>
